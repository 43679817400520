import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';

import HowItWorks from '../components/HowItWorks';

import logo from '../../static/img/logo_white.png';
import seaweedMap from '../../static/img/seaweed_map.jpg';
import homeJumbotron from '../../static/img/home-jumbotron.jpg';

export default function IndexPageTemplate({
  title,
  subheading,
  mainpitch,
  intro,
}) {
  return (
    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: ` linear-gradient(0deg, rgba(0,0,0,.7), rgba(0,0,0,.3)), url(${homeJumbotron})`,
          backgroundPosition: 'bottom left',
          backgroundAttachment: 'fixed',
        }}
      >
        <div
          style={{
            display: 'flex',
            lineHeight: '1',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img src={logo} style={{ width: 100, height: 100 }} alt="Algasat Logo" />
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              textShadow: 'rgba(0,0,0,.5)',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
              textAlign: 'center',
            }}
          >
            {title}
          </h1>
          <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
              textShadow: 'rgba(0,0,0,.5)',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
              textAlign: 'center',
            }}
          >
            {subheading}
          </h3>
        </div>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column  is-offset-1">
                <div className="content">
                  <div className="content" style={{ flexDirection: 'row', display: 'flex' }}>
                    <Grid container >
                      <Grid item sm={12} md={6}>
                        <img src={seaweedMap} style={{
                          borderRadius: 5,
                          marginTop: 10,
                          marginBottom: 10,
                          width: 600,
                        }} alt="Seaweed Map" />
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <div style={{ marginLeft: 10 }}>
                          <div className="tile">
                            <h1 className="title">{mainpitch.title}</h1>
                          </div>
                          <div className="tile">
                            {mainpitch.description}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <HowItWorks gridItems={intro.blurbs} />
                  <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <Button color="primary" variant="outlined" href="/how-it-works">Learn more</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};
