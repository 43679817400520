import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const FeatureGrid = ({ gridItems }) => (
  <>
    <div className="columns">
      <div className="column is-12">
        <h3 className="has-text-weight-semibold is-size-2">
          How It Works?
        </h3>
      </div>
    </div>
    <div className="columns is-multiline">
      {gridItems.map((item) => (
        <div key={item.text} className="column is-4">
          <section className="section">
            <div className="has-text-centered">
              <div
                style={{
                  width: '240px',
                  display: 'inline-block',
                }}
              >
                <PreviewCompatibleImage imageInfo={item} />
              </div>
              <p>{item.text}</p>
            </div>
          </section>
        </div>
      ))}
    </div>
  </>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    }),
  ),
};

export default FeatureGrid;
